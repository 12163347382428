*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  font-family: "Circular Book", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #3e364a;
}

body {
  height: 100%;
}

a {
  text-decoration: none;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

::-moz-selection {
  background: #fa5d51;
}
::selection {
  background: #fa5d51;
}
