/* Domaine */
@font-face {
  font-family: "Domaine Disp Nar";
  src: url("/fonts/domaine/DomaineDispNar-Regular.eot");
  src: url("/fonts/domaine/DomaineDispNar-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/domaine/DomaineDispNar-Regular.woff2") format("woff2"),
    url("/fonts/domaine/DomaineDispNar-Regular.woff") format("woff"),
    url("/fonts/domaine/DomaineDispNar-Regular.ttf") format("truetype"),
    url("/fonts/domaine/DomaineDispNar-Regular.svg#DomaineDispNar-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Domaine Disp Nar Semibold";
  src: url("/fonts/domaine/DomaineDispNarSemibold.eot");
  src: url("/fonts/domaine/DomaineDispNarSemibold.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/domaine/DomaineDispNarSemibold.woff2") format("woff2"),
    url("/fonts/domaine/DomaineDispNarSemibold.woff") format("woff"),
    url("/fonts/domaine/DomaineDispNarSemibold.ttf") format("truetype"),
    url("/fonts/domaine/DomaineDispNarSemibold.svg#DomaineDispNarSemibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Domaine Disp Nar Italic";
  src: url("/fonts/domaine/DomaineDispNarSemibold-Italic.eot");
  src: url("/fonts/domaine/DomaineDispNarSemibold-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/domaine/DomaineDispNarSemibold-Italic.woff2") format("woff2"),
    url("/fonts/domaine/DomaineDispNarSemibold-Italic.woff") format("woff"),
    url("/fonts/domaine/DomaineDispNarSemibold-Italic.ttf") format("truetype"),
    url("/fonts/domaine/DomaineDispNarSemibold-Italic.svg#DomaineDispNarSemibold-Italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Circular */
@font-face {
  font-family: "Circular Bold";
  src: url("/fonts/circular/CircularPro-Bold.eot");
  src: url("/fonts/circular/CircularPro-Bold.woff2");
  src: url("/fonts/circular/CircularPro-Bold.woff");
  src: url("/fonts/circular/CircularPro-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Circular Medium";
  src: url("/fonts/circular/CircularPro-Medium.eot");
  src: url("/fonts/circular/CircularPro-Medium.woff2");
  src: url("/fonts/circular/CircularPro-Medium.woff");
  src: url("/fonts/circular/CircularPro-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Circular Book";
  src: url("/fonts/circular/CircularPro-Book.eot");
  src: url("/fonts/circular/CircularPro-Book.woff2");
  src: url("/fonts/circular/CircularPro-Book.woff");
  src: url("/fonts/circular/CircularPro-Book.ttf");
  font-weight: normal;
  font-style: normal;
}
